import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ROOT } from '../../api-config';
import Swal from 'sweetalert2'

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            hash: "",
            one_click: "",
            auth_code: ""
        };
    }

    componentDidMount() {
        document.title = "Licensee Login | BevOps";
        if (typeof this.props.match.params.one_click !== "undefined") {
            this.setState({one_click: this.props.match.params.one_click}, () => {
                this.login()
            });
        }
        if (typeof this.props.match.params.hash !== "undefined") {
            this.setState({hash: this.props.match.params.hash}, () => {
                this.login()
            });
        }
        if (typeof this.props.match.params.auth_code !== "undefined") {
            this.setState({auth_code: this.props.match.params.auth_code}, () => {
                this.login()
            });
        }
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    login(){
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(`${API_ROOT}/login`, {
            withCredentials: true,
            email: this.state.email,
            password: this.state.password,
            one_click: this.state.one_click,
            hash: this.state.hash,
            auth_code: this.state.auth_code
        })
            .then(function (response) {
                localStorage.setItem('first_name', response.data.first_name);
                localStorage.setItem('last_name', response.data.last_name);
                localStorage.setItem('accounts', JSON.stringify(response.data.accounts));
                if (typeof self.props.match.params.account_id !== "undefined") {
                    self.props.updateActiveAccount(self.props.match.params.account_id)
                }
                self.props.refresh_from_local();
                self.props.userHasAuthenticated(true);
                if (self.state.one_click.length > 1 && response.data.reset_required == 0) {
                    self.props.history.push("/place-order");
                } else if (self.state.hash == "" && response.data.reset_required == 0) {
                    self.props.history.push("/dashboard");
                } else {
                    self.props.history.push("/aboutme/reset");
                }
            })
            .catch(function (error) {
                Swal({
                    type:'error',
                    title: error.response.data.Message,
                    text: error.response.data.Info,
                    showCloseButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#3085d6',
                    reverseButtons:true,
                    focusConfirm: false,
                    cancelButtonText: 'Try Again',
                    confirmButtonText: 'Reset Password',
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: function() {
                        return new Promise(function(resolve) {
                            axios.post(`${API_ROOT}/user/reset`, { withCredentials: true, email: self.state.email})
                                .then(response => {
                                    resolve();
                                })
                        })
                    }
                }).then((result) => {
                    if (result.value) {
                        Swal("Check Your Email", "If an account is associated with "+self.state.email+", a password reset link will be sent there momentarily.", "success")
                    }
                });
            });
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();
        this.login()
    };

    render() {
        return (
            <div className="block-center mt-4 wd-xl">
                <div className="card card-flat">
                    <div className="card-header text-center bg-dark">
                        <a href="">
                            <img className="block-center rounded logo_container" src="img/logo.png" alt="Logo" style={{width: "177px"}}/>
                        </a>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2 mb-0">LICENSEE PORTAL</p>
                        <form className="mb-3" id="loginForm" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="email" type="email" placeholder="Enter email" autoComplete="off" onChange={this.handleChange}
                                           required/>
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-envelope text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <input className="form-control border-right-0" id="password" type="password" placeholder="Password" onChange={this.handleChange} required/>
                                    <div className="input-group-append">
                                        <span className="input-group-text fa fa-lock text-muted bg-transparent border-left-0"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix" style={{'display':'none'}}>
                                <div className="checkbox c-checkbox float-left mt-0">
                                    <label>
                                        <input type="checkbox" value="" name="remember"/>
                                        <span className="fa fa-check"></span>Remember Me</label>
                                </div>
                                <div className="float-right">
                                    <Link to="recover" className="text-muted">Forgot your password?</Link>
                                </div>
                            </div>
                            <button className="btn btn-block btn-primary mt-3" type="submit" disabled={!this.validateForm()}>Sign In</button>
                        </form>
                        <br></br>
                        <Link to="register" className="btn btn-block btn-secondary">Sign Up Now</Link>
                        <p className="pt-3 text-center"><a href="mailto:info@thewebpeople.ca">Questions / Support</a></p>
                    </div>
                </div>
                <div className="p-3 text-center">
                    <span className="mr-2">&copy;</span>
                    <span>2025</span>
                    <span className="mx-2">-</span>
                    <span>BevOps</span>
                </div>
            </div>
        );
    }
}

export default Login;
